import React from "react"
import { Box, Container, Link, makeStyles, Typography } from "@material-ui/core"

import Layout from "../components/layout"
import Seo from "../components/seo"

// import Icon404 from '../svg/404.svg'
import { Svg404 } from '../svg-js/svg-images';


const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '40px 0',
  },

  titleBox: {
    textAlign: 'center',
  },

  link: {
    fontSize: '1.3rem',
    textDecoration: 'underline',
    
    '&:hover': {
      color: [theme.palette.primary.main],
      textDecorationColor: [theme.palette.primary.main],
    },
  },

  svg: {
    width: '100%',
    height: '100%',
  },

  [theme.breakpoints.up('md')]: {
    wrapper: {
      padding: '60px 0 120px',
    },
    titleBox: {
      width: '65%',
      textAlign: 'left',
    },
  },

  [theme.breakpoints.up('lg')]: {
    wrapper: {
      padding: '60px 0 120px',
    },
    svg: {
      width: 'auto',
      height: 'auto',
    },
  },
}))

const NotFoundPage = () => {

  const classes = useStyles()

  return (
    <Layout fixedHeader={false}>
      <Seo title="404: Страница не найдена" />
      <Box component="section" className={classes.wrapper}>
        <Container maxWidth="xl">
          <Box className={classes.titleBox} mb={{xs: 5, md: 7}}>
            <Typography variant="h1" className={`${classes.title} title`}>упс....кажется, такой страницы не существует</Typography>
            <Link className={classes.link} href="/" color="inherit">Вернуться на главную</Link>
          </Box>
          <Box>
            <Svg404 className={classes.svg} />
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default NotFoundPage
